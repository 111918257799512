import React from 'react'
import { graphql } from 'gatsby'
import Hero, { HeroData } from '../../Hero'

export const ParagraphHeroFields = graphql`
  fragment ParagraphHeroFields on paragraph__hero {
    __typename
    id
    field_subtitle
    field_title
    relationships {
      field_image {
        thumbnail {
          alt
        }
        gatsbyImageData(width: 1200, aspectRatio: 2)
        relationships {
          field_media_image {
            uri {
              value
            }
          }
        }
      }
    }
    field_cta {
      uri
      title
      uri_alias
    }
  }
`

const ParagraphHero = (props: HeroData) => <Hero hero={props} />

export default ParagraphHero
